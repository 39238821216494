import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'Index',
    path: '/',
    component: () => import('../views/claim/claim.vue'),
    meta: {
      title: '用户领取',
    },
  },
  {
    name: 'claim',
    path: '/claim',
    component: () => import('../views/claim/claim.vue'),
    meta: {
      title: '用户领取',
    },
  },
  {
    name: 'SingClaim',
    path: '/sing/claim',
    component: () => import('../views/claim/singClaim.vue'),
    meta: {
      title: '用户领取',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    name: 'SingLogin',
    path: '/sing/login',
    component: () => import('../views/login/singLogin.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    name: 'Order',
    path: '/order',
    component: () => import('../views/order/order.vue'),
    // component: () => import('../views/404.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'OrderNew',
    path: '/order/new',
    component: () => import('../views/order/order2.vue'),
    // component: () => import('../views/404.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'OrderNew2',
    path: '/order_29',
    component: () => import('../views/order/order3.vue'),
    // component: () => import('../views/404.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'OrderNew2',
    path: '/order_29',
    component: () => import('../views/order/order3.vue'),
    // component: () => import('../views/404.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'Order19',
    path: '/order_19',
    component: () => import('../views/order/order5.vue'),
    // component: () => import('../views/404.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'OrderNew3',
    path: '/order_29_tmp',
    component: () => import('../views/order/order4.vue'),
    // component: () => import('../views/404.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'Telecom',
    path: '/telecom',
    component: () => import('../views/telecom/order.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'SingOrder',
    path: '/sing/order',
    component: () => import('../views/order/singOrder.vue'),
    meta: {
      title: '下单',
    },
  },
  {
    name: 'pay_callback',
    path: '/callback',
    component: () => import('../views/callback/callback.vue'),
    meta: {
      title: '签约成功',
    },
  },
  {
    name: 'pay_callback',
    path: '/callback',
    component: () => import('../views/callback/callback.vue'),
    meta: {
      title: '支付成功',
    },
  },
  {
    name: 'single',
    path: '/single',
    component: () => import('../views/single/index.vue'),
    meta: {
      title: '会员购买',
    },
  },
  {
    name: 'Notfound',
    path: '/404',
    component: () => import('../views/404.vue'),
    meta: {
      title: '支付成功',
    },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory('/'),
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export { router }
